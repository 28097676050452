
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

function generateRandomId(length: number): string {
  const chars = 'abcdefghijklmnopqrstuvwxyz0123456789'
  let randomId = ''
  for (let i = 0; i < length; i++) {
    randomId += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return randomId
}

export default defineComponent({
  name: 'Home',
  created() {
    console.log('Home:created()')
    this.$store.dispatch('loadPolls')
  },
  data() {
    return {
      examplePollId: 'wtgf1337'
    }
  },
  computed: mapState(['polls']),
  methods: {
    createPoll() {
      this.$router.push({
        name: 'Settings',
        params: { pollId: generateRandomId(16) }
      })
    },
    voteInPoll(pollId: string) {
      this.$router.push({
        name: 'Vote',
        params: { pollId: pollId }
      })
    },
    editPoll(pollId: string) {
      this.$router.push({
        name: 'Settings',
        params: { pollId: pollId }
      })
    },
    deletePoll(pollId: string) {
      this.$store.dispatch('deletePoll', pollId)
    },
    startVoting() {
      this.voteInPoll(this.examplePollId)
    }
  }
})
