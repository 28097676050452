
import { defineComponent } from 'vue'
import { mapState } from 'vuex'

export default defineComponent({
  name: 'EditChoices',
  created() {
    console.log('EditChoices:created()')
  },
  data() {
    return {
      newChoiceText: ''
    }
  },
  computed: mapState(['choices', 'pollId']),
  methods: {
    addChoice(title: string) {
      this.$store.commit('createChoice', title)
    },
    addNewChoice() {
      this.addChoice(this.newChoiceText)
      this.newChoiceText = ''
    },
    addExampleChoices() {
      this.$store.dispatch('addExampleChoices')
    },
    clearChoices() {
      this.$store.commit('clearBallots')
      this.$store.commit('clearChoices')
    }
  }
})
