
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { Ballot } from '@/types'
import { Choice } from '@/types'
import EventService from '@/services/EventService'

export default defineComponent({
  name: 'EditBallot',
  props: {
    pollId: {
      type: String,
      required: true
    },
    voterId: {
      type: String,
      required: true
    }
  },
  emits: {
    ballotWasCast: null
  },
  created() {
    console.log('EditBallot:created()')
    // this.clearBallot()
  },
  data() {
    return {
      ballot: [] as Choice[]
    }
  },
  computed: {
    ...mapState(['poll', 'choices', 'ballots']),
    unusedChoices: function() {
      // List of choices that are not yet included in ballot
      const unused: Choice[] = []
      for (let i = 0; i < this.choices.length; i++) {
        if (!this.isUsed(this.choices[i])) {
          unused.push(this.choices[i])
        }
      }
      return unused
    }
  },
  methods: {
    addToBallot(choice: Choice) {
      console.log('addToBallot(', choice, ')')
      if (!this.ballot.includes(choice)) {
        this.ballot.push(choice)
      }
    },
    clearBallot() {
      console.log('clearBallot()')
      this.ballot = []
    },
    castBallot() {
      console.log('castBallot()')
      const voterId: string = this.voterId.toString()
      const ballot: Ballot = {
        voterId: voterId,
        choices: this.ballot
      }
      this.$store.commit('addBallot', ballot)
      EventService.saveBallot(this.poll, ballot)
        .then(response => {
          console.log('Ballot was saved', response)
        })
        .catch(error => {
          console.warn('Failed to save ballot:', error)
        })
      this.clearBallot()
      this.$emit('ballotWasCast')
    },
    isUsed(choice: Choice): boolean {
      for (let i = 0; i < this.ballot.length; i++) {
        if (this.ballot[i].id === choice.id) {
          return true
        }
      }
      return false
    }
  }
})
