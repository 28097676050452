
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import { Poll } from '@/types'
import { getSchulzeMethod } from '@/methods/schulze'
import EventService from '@/services/EventService'

export default defineComponent({
  name: 'EditPoll',
  props: {
    pollId: {
      type: String,
      required: true
    }
  },
  created() {
    console.log('EditPoll:created()')
    this.localPollId = this.pollId
    if (this.poll.id != '') {
      // Poll already exists, initialize with current values.
      this.localPollId = this.poll.id
      this.pollTitle = this.poll.title
      this.pollDescription = this.poll.description
    }
  },
  data() {
    return {
      localPollId: '',
      pollTitle: '',
      pollDescription: ''
    }
  },
  computed: mapState(['poll', 'choices']),
  methods: {
    savePoll() {
      const poll: Poll = {
        id: this.localPollId,
        title: this.pollTitle,
        description: this.pollDescription,
        public: true,
        method: getSchulzeMethod()
      }
      this.$store.commit('setPoll', poll)
      EventService.savePoll(poll)
        .then(response => {
          console.log(response)
        })
        .catch((error: string) => {
          console.log(error)
        })
      for (let i = 0; i < this.choices.length; i++) {
        EventService.saveChoice(poll, this.choices[i])
      }
      if (this.localPollId != this.pollId) {
        console.log('Poll ID changed from', this.pollId, 'to', this.localPollId)
        // Update router
        this.$router.push({
          name: 'Settings',
          params: { pollId: this.localPollId }
        })
      }
    },
    startVoting() {
      this.savePoll()
      this.$router.push({
        name: 'Vote',
        params: { pollId: this.poll.id }
      })
    }
  }
})
