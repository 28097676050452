
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NavBar',
  methods: {
    isActiveTab(tabName: string): boolean {
      // console.log(this.$route.name)
      if (this.$route.name === tabName) {
        return true
      }
      return false
    }
  }
})
