
import { defineComponent, PropType } from 'vue'
import { mapState } from 'vuex'
import { Choice, Result } from '@/types'

export default defineComponent({
  name: 'ShowResult',
  props: {
    result: {
      type: Object as PropType<Result>,
      required: true
    }
  },
  computed: mapState(['choices']),
  methods: {
    getMatrixCellString(
      result: Result,
      choiceA: Choice,
      choiceB: Choice
    ): string {
      if (choiceA.id === choiceB.id) {
        return '-'
      }
      let str = '('
      str += result.pairwiseResults[choiceA.id][
        choiceB.id
      ].choiceA.points.toString()
      str += ','
      str += result.pairwiseResults[choiceA.id][
        choiceB.id
      ].choiceB.points.toString()
      str += ')'
      return str
    },
    getPairwiseResultString(
      result: Result,
      choiceA: Choice,
      choiceB: Choice
    ): string {
      if (choiceA.id === choiceB.id) {
        return ''
      }
      let str = choiceA.title + ' vs. ' + choiceB.title + ': '
      const scoreA =
        result.pairwiseResults[choiceA.id][choiceB.id].choiceA.points
      const scoreB =
        result.pairwiseResults[choiceA.id][choiceB.id].choiceB.points
      str += scoreA.toString() + ' - ' + scoreB.toString()
      if (scoreA > scoreB) {
        str += ' Winner: ' + choiceA.title
      } else if (scoreB > scoreA) {
        str += ' Winner: ' + choiceB.title
      } else {
        str += ' Tie'
      }
      return str
    },
    getLosesTo(result: Result, choiceA: Choice): string {
      const losesTo: Choice[] = []
      for (let i = 0; i < this.choices.length; i++) {
        const choiceB = this.choices[i]
        if (choiceA.id != choiceB.id) {
          const scoreA =
            result.pairwiseResults[choiceA.id][choiceB.id].choiceA.points
          const scoreB =
            result.pairwiseResults[choiceA.id][choiceB.id].choiceB.points
          if (scoreB > scoreA) {
            losesTo.push(choiceB)
          }
        }
      }
      if (losesTo.length === 0) {
        return 'none'
      }
      let losesToString = losesTo[0].title
      for (let i = 1; i < losesTo.length - 1; i++) {
        losesToString += ', ' + losesTo[i].title
      }
      if (losesTo.length > 1) {
        losesToString += ' and ' + losesTo[losesTo.length - 1].title
      }
      return losesToString
    },
    getWinnersString(result: Result): string {
      let winnerString = 'No winner'
      if (result.winners.length == 0) {
        return winnerString
      }
      winnerString = 'Winner: ' + result.winners[0].title
      if (result.winners.length == 1) {
        return winnerString
      }
      // More than one winner
      winnerString = 'Winners: ' + result.winners[0].title
      for (let i = 1; i < result.winners.length; i++) {
        winnerString += ', ' + result.winners[i].title
      }
      return winnerString
    }
  }
})
