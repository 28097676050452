
import { defineComponent } from 'vue'
import EditChoices from '@/components/EditChoices.vue'
import EditPoll from '@/components/EditPoll.vue'
import PollBar from '@/components/PollBar.vue'

export default defineComponent({
  name: 'Settings',
  props: {
    pollId: {
      type: String,
      required: true
    }
  },
  components: {
    EditChoices,
    EditPoll,
    PollBar
  }
})
