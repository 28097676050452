
import { defineComponent } from 'vue'
import { mapState } from 'vuex'
import EditBallot from '@/components/EditBallot.vue'
import Ballots from '@/components/Ballots.vue'
import PollBar from '@/components/PollBar.vue'

export default defineComponent({
  name: 'Vote',
  props: {
    pollId: {
      type: String,
      required: true
    }
  },
  components: {
    Ballots,
    EditBallot,
    PollBar
  },
  created() {
    this.nextVoter()
  },
  data() {
    return {
      voterId: '',
      checkForNextVoter: false
    }
  },
  computed: mapState(['choices', 'ballots']),
  methods: {
    afterCastBallot() {
      console.log('afterCastBallot')
      this.checkForNextVoter = true
    },
    nextVoter() {
      console.log('nextVoter()')
      const nextVoterIndex: number = this.ballots.length + 1
      this.voterId = 'voter' + nextVoterIndex.toString()
      this.checkForNextVoter = false
    },
    goToResults() {
      console.log('goToResults()')
      this.$router.push({
        name: 'Results',
        params: { pollId: this.pollId }
      })
    }
  }
})
